@import 'bootstrap/dist/css/bootstrap.css';
@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700&subset=cyrillic,cyrillic-ext,latin-ext');

/* Mobile first */

/*========================================================================
                            Global styles
==========================================================================*/
body {
  overscroll-behavior-y: none;
  font-family: 'Open Sans', sans-serif;
  font-size: 12px;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h4,
.h4 {
  font-size: 18px;
  font-weight: 400;
  text-transform: uppercase;
}

h5,
.h5 {
  text-align: center;
  font-weight: 400;
  color: #939598;
  text-transform: uppercase;
  font-size: 14px;
}

p {
  font-size: 13px;
  font-weight: 400;
}

a {
  color: #939598;
}

a:hover {
  text-decoration: none;
  color: #ffcb08;
}

a:focus {
  text-decoration: none;
  color: #ffcb08;
}

small {
  color: #000;
  font-size: 10px;
}

button {
  cursor: pointer;
}

button:focus,
input:focus {
  outline-offset: 0;
  outline: none;
}

/*========================================================================
                            Layout
==========================================================================*/
.main-header {
  background-color: rgba(0, 0, 0, 0.85);
}

/*** BUTTONS ***/

.btn-yellow,
.btn-white {
  cursor: pointer;
  display: inline-block;
  width: 182px;
  height: 40px;
  line-height: 40px;
  font-weight: 700;
  margin: 10px 0;
  font-size: 14px;
  text-transform: uppercase;
}

.btn-yellow {
  color: #000;
  background: #ffcb08;
}

.btn-yellow:hover,
.btn-yellow:focus {
  color: #fff;
}

.btn-white {
  border: 1px solid #fff;
  background-color: transparent;
  color: #fff;
}

.btn-white:hover,
.btn-white:focus {
  border: 1px solid #ffcb08;
  background-color: transparent;
  color: #ffcb08;
}

/*** ARROWS ***/
.arrow-yellow {
  color: #ffcb08;
  font-size: 16px;
  top: 2px;
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  text-align: right;
}

.sign-in-home {
  position: relative;
  padding-right: 10px;
}

/** UL SQUARE **/
.square-ul {
  display: inline-block;
  width: 6.5px;
  height: 6.5px;
  background: transparent;
  border: 1px solid #000;
  margin-right: 5px;
  margin-bottom: 2px;
}

.main-nav ul li.active a {
  position: relative;
  display: block;
}

.main-nav ul li.active a::after {
  content: '';
  display: table;
  border-bottom: 1px solid #fdcb07;
  width: 100%;
  position: absolute;
  left: 0;
  right: 0;
  bottom: -5px;
}

/*========================================================================
                            Specific
==========================================================================*/
.container-fluid.contest-photo-list {
  padding: 0;
}

.contest-photo-list .row,
.contest-photo-list .col-md-3 {
  padding: 0;
  margin: 0;
}

.list-reset {
  margin: 0;
  padding: 0;
  list-style: none;
}

/*========================================================================
                            Home
==========================================================================*/

.logo {
  line-height: 60px;
}

.logo img {
  width: 99px;
  height: 45px;
}

.log-reg {
  text-align: right;
  display: flex;
  flex-flow: column;
  justify-content: center;
  height: 60px;
}

.log-reg li a {
  color: #bcbec0;
  font-weight: 400;
}

.log-reg li a:hover {
  color: #ffcb08;
}

.mobile-bt {
  border-top: 1px solid #444444;
}

.main-nav {
  text-align: center;
}

.main-nav ul {
  padding: 10px 0;
  display: inline-block;
}

.main-nav ul li {
  padding: 7px 0;
}

.main-nav ul li a {
  text-transform: uppercase;
  color: #fff;
  font-weight: 400;
  font-size: 12px;
  letter-spacing: 1px;
}

.main-nav ul li a:hover {
  color: #ffcb08;
}

#home .hero-container {
  background-image: url('/img/ss-bg.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  text-align: center;
  color: #fff;
  border-bottom: 3px solid #ffcb08;
}

.hero-container .hero-overlay-bg {
  background-color: rgba(35, 31, 32, 0.2);
  padding: 30px 0;
}

#home .hero-container p {
  font-size: 14px;
}

#contest-logo {
  text-indent: -9999px;
  background-image: url('/img/ss-logo.svg');
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center center;
  width: 160px;
  height: 165px;
  margin: 0 auto 20px;
}

.awards-section {
  padding: 20px 0;
}

.awards-section article {
  padding: 20px 0;
}

.awards-section article > h4 {
  margin-bottom: 18px;
}

.awards-section article > p {
  color: #939598;
  font-size: 13px;
  margin-bottom: 16px;
}

.awards-section article > ul li,
.awards-section article > a {
  font-size: 13px;
}

.awards-section article > ul {
  margin-bottom: 18px;
}

.awards-section .awards-line::after,
.awards-section .awards-line::before {
  content: '';
  display: block;
  width: 80%;
  border-bottom: 1px solid #939598;
  margin: auto;
  background-color: #fff;
}

.contest-counter {
  position: relative;
}

.contest-counter::before {
  background-color: #939598;
  content: '';
  display: block;
  height: 1px;
  position: absolute;
  top: 50%;
  width: 100%;
  z-index: -1;
}

.contest-counter .contest-counter-clock {
  z-index: 9;
  margin: 0 auto;
  width: 220px;
  background: white;
}

.contest-counter .contest-counter-clock > div {
  display: inline-block;
}

.contest-counter h5 {
  margin-bottom: 10px;
}

.contest-counter .contest-counter-clock > div span {
  font-size: 10px;
}

.contest-counter .contest-counter-clock > div .cc-number {
  display: block;
  font-size: 27px;
  font-weight: 600;
  width: 35px;
  height: 43px;
  background-color: #939598;
  color: #fff;
  margin: 0 7px;
}

.sort-by {
  text-align: center;
  font-size: 11px;
  padding: 15px 0;
  display: flex;
  justify-content: center;
}

.sort-by p {
  font-size: 11px;
  margin: 0;
}

.sort-by li {
  display: inline-block;
  padding: 0 7px;
  border-right: 1px solid #000;
}

.sort-by li:last-of-type {
  border-right: none;
}

.sort-by li a {
  color: #000;
  font-size: 11px;
}

.sort-by li a:hover {
  color: #ffcb08;
}

.sort-by .sb-active {
  font-weight: 700;
}

.contest-photo-list-item {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  display: block;
  padding-bottom: 100%;
  position: relative;
  cursor: pointer;
}

.cpli-number {
  position: absolute;
  font-weight: 700;
  left: 0;
  right: 18px;
  top: 10px;
  bottom: 0;
  text-align: right;
  font-size: 16px;
  color: #fff;
  text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.8);
}

@media screen and (min-width: 769px) {
  .contest-photo-list-item .cpli-content,
  .contest-photo-list-item .cpli-overlay {
    display: none;
  }

  .contest-photo-list-item:hover > * {
    display: initial;
  }

  .contest-photo-list-item:hover .cpli-number {
    color: #ffcb08;
  }
}

.cpli-number {
  left: auto;
  bottom: auto;
  z-index: 8;
}

ul.pagination {
  display: block;
  text-align: center;
  padding: 20px 0;
  margin: 0;
  font-size: 0;
}

ul.pagination li {
  padding: 0 8px;
  display: inline-block;
}

ul.pagination li a {
  font-size: 16px;
  color: #000;
  user-select: none;
}

ul.pagination li a:hover {
  color: #ffcb08;
}

ul.pagination li.active span,
ul.pagination li.active a {
  font-size: 16px;
  color: #000;
  font-weight: 700;
  user-select: none;
  cursor: default;
}

ul.pagination li.disabled {
  padding: 0;
  cursor: default;
}

ul.pagination li.disabled span,
ul.pagination li.disabled a,
ul.pagination .pag-next a {
  font-size: 16px;
  color: #a7a9ac;
  user-select: none;
  cursor: default;
}

ul.pagination li:first-of-type,
ul.pagination li:last-of-type {
  padding: 0;
}

footer .footer-strip {
  background-color: #000;
  padding: 30px 0;
}

.footer-logo {
  max-height: 100% !important;
  max-width: 100%;
  margin: 0 0 20px 0;
}

.fla {
  text-align: center;
}

footer .footer-nav {
  margin-bottom: 20px;
  text-align: center;
}

footer .footer-nav ul {
  text-align: left;
}

footer .center-mobile {
  display: inline-block;
}

footer .footer-nav ul li a {
  color: #fff;
  font-size: 12px;
}

footer .footer-nav ul li a:hover {
  color: #fdcb07;
}

footer .newsletter {
  position: relative;
  width: 220px;
  margin: auto;
}

footer .newsletter input {
  width: 220px;
  height: 40px;
  color: #fff;
  background: #000;
  border: 1px solid white;
  padding-left: 10px;
  font-size: 12px;
}

footer .newsletter ::-webkit-input-placeholder {
  /* Chrome */
  color: #fff;
}

footer .newsletter :-ms-input-placeholder {
  /* IE 10+ */
  color: #fff;
}

footer .newsletter ::-moz-placeholder {
  /* Firefox 19+ */
  color: #fff;
  opacity: 1;
}

footer .newsletter :-moz-placeholder {
  /* Firefox 4 - 18 */
  color: #fff;
  opacity: 1;
}

footer .newsletter input:focus {
  outline-offset: 0;
  outline: none;
  border-color: #fdcb07;
}

footer .newsletter button {
  position: absolute;
  top: 0;
  right: 8px;
  bottom: 0;
  background: transparent;
  border: none;
  padding: 0;
  color: #fff;
  font-size: 16px;
  line-height: 0;
  cursor: pointer;
}

footer .newsletter button:hover {
  color: #fdcb07;
}

footer .footer-social {
  margin-bottom: 0;
  margin-top: 20px;
  text-align: center;
}

footer .footer-social li {
  display: inline-block;
}

footer .footer-social li a {
  color: #fff;
  font-size: 36px;
}

footer .footer-social li a:hover {
  color: #fdcb07;
}

footer .footer-social li:first-of-type {
  margin-right: 20px;
}

footer .copyright {
  padding: 20px 0;
  margin: 0;
}

.error-box {
  display: flex;
  align-items: center;
  font-size: 11px;
  margin: 5px 0 -15px 0;
  padding: 0;
  color: red;
  height: 16px;
}

.error-block {
  color: red;
  font-size: 12px;
  text-align: center;
}

@media only screen and (max-width: 768px) {
  .cpli-overlay,
  .cpli-content {
    display: none;
  }
  .main-header {
    background-color: #000;
  }
}

/* Small Devices, Tablets */

@media only screen and (min-width: 769px) {
  .konkurs-img:hover {
    -moz-transform: scale(1.7);
    -webkit-transform: scale(1.7);
    -o-transform: scale(1.7);
    -ms-transform: scale(1.7);
    transform: scale(1.7);
    z-index: 8;
  }

  .main-header {
    position: fixed;
    left: 0;
    right: 0;
    z-index: 9;
    background-color: rgba(0, 0, 0, 0.85);
  }

  .main-nav ul {
    line-height: 60px;
    padding: 0;
    display: block;
  }

  .main-nav ul li {
    padding: 0 10px;
    border-right: 1px solid #fff;
    line-height: 1;
    display: inline-block;
  }

  .main-nav ul li:first-of-type {
    padding-left: 0;
  }

  .main-nav ul li:last-of-type {
    border-right: none;
    padding-right: 0;
  }

  .mobile-bt {
    border: none;
  }

  .hero-btn-left {
    text-align: right;
  }

  .hero-btn-right {
    text-align: left;
  }

  .hero-btn-left a,
  .hero-btn-right a {
    text-align: center;
  }

  #home .hero-container .hero-overlay-bg {
    padding: 110px 0 50px 0;
  }

  #contest-logo {
    margin-bottom: 30px;
  }

  #home .hero-container p {
    margin-bottom: 25px;
    font-weight: 400;
  }

  .btn-yellow,
  .btn-white {
    margin-top: 0;
    margin-bottom: 5px;
  }

  .awards-line {
    position: relative;
  }

  .awards-section .awards-line::after,
  .awards-section .awards-line::before {
    background-color: transparent;
    border-bottom: none;
    border-top: none;
    position: absolute;
    bottom: 0;
    top: 0;
    z-index: -1;
  }

  .awards-line::before {
    content: '';
    display: block;
    height: 67%;
    left: 0;
    border-left: 1px solid #939598;
    margin: auto;
  }

  .awards-line::after {
    content: '';
    display: block;
    height: 67%;
    border-right: 1px solid #939598;
    margin: auto;
    right: 0;
  }

  .sort-by {
    text-align: right;
    font-size: 10px;
    padding: 15px 15px 15px 0;
  }

  .sort-by p,
  .sort-by ul {
    display: inline-block;
  }

  .box-view-action {
    color: white;
    font-weight: bold;
    font-size: 20px;
  }

  .cpli-overlay {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
  }

  .cpli-content {
    position: absolute;
    color: #fff;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
  }

  .cpli-number-active {
    color: #ffcb08;
  }

  .cpli-content i {
    font-size: 16px;
    color: #fff;
  }

  .cpli-vote-strip {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }

  .cpli-vote-strip div {
    display: flex;
  }

  .cpli-vote-strip .cpli-rank-container {
    display: flex;
    align-items: flex-end;
    margin-right: 12px;
  }

  .cpli-vote-strip div span {
    font-size: 16px;
    font-weight: 700;
  }

  .login-required {
    position: absolute;
    bottom: 10px;
    left: 0;
    right: 0;
  }

  .login-required p {
    font-size: 10px;
    margin-bottom: 10px;
  }

  .login-required .btn-white {
    width: 126px;
    height: 28px;
    font-size: 10px;
    line-height: 26px;
    margin-bottom: 0;
  }

  ul.pagination {
    text-align: right;
    padding: 20px 15px 20px 0;
  }

  footer .footer-strip {
    height: 140px;
    display: flex;
    flex-flow: column;
    justify-content: center;
    padding: 0;
  }

  .footer-logo {
    margin: 0;
  }

  footer .footer-nav {
    margin: 0;
    display: flex;
    flex-flow: row;
    padding: 0;
    align-items: center;
  }

  footer .footer-nav ul {
    display: inline-block;
    margin-top: 0;
    margin-bottom: 0;
    text-align: left;
    border-left: 2px solid #fdcb07;
    padding-left: 10px;
  }

  footer .footer-nav ul:first-of-type {
    margin-right: 30px;
  }

  footer .footer-nav ul li {
    margin: 0;
    padding: 0;
    line-height: 1;
  }

  footer .footer-nav ul li {
    margin-bottom: 4px;
  }

  footer .footer-nav ul li:last-of-type {
    margin-bottom: 0;
  }

  footer .footer-social {
    font-size: 0;
    text-align: right;
    margin: 0;
  }

  footer .footer-social li,
  footer .footer-social li a {
    line-height: 0;
  }

  footer .newsletter-align,
  footer .social-align {
    display: flex;
    padding: 0;
    align-items: center;
    justify-content: flex-end;
  }

  footer .newsletter {
    float: right !important;
    margin: 0;
  }

  .fla {
    text-align: left;
  }
}

@media only screen and (min-width: 992px) {
  .cpli-content i {
    font-size: 22px;
    color: #fff;
  }

  .cpli-vote-strip div span {
    font-size: 19px;
    font-weight: 700;
  }

  .login-required {
    bottom: 20px;
  }

  .cpli-number {
    font-size: 19px;
    top: 10px;
    right: 18px;
  }
}

@media (min-width: 769px) and (max-width: 991px) {
  .center-mobile {
    text-align: left;
  }

  .center-mobile ul {
    border-left: none !important;
    margin: 0;
    padding-left: 0 !important;
  }
}

@media (min-width: 992px) {
  .center-mobile {
    display: flex !important;
  }
}

/*========================================================================
                            Login
==========================================================================*/

.top-right {
  position: absolute;
  top: 0;
  right: 0;
  border-radius: 0;
  color: #000;
  background-color: #fff;
  border: none;
  width: 20px;
  height: 20px;
  line-height: 20px;
  text-align: center;
}

.top-right:hover {
  background-color: #fff;
  color: #fdcb07;
}

.modal-mask {
  position: fixed;
  z-index: 99;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  transition: opacity 0.3s ease;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-wrapper {
  position: relative;
  width: 100%;
  max-width: 580px;
}

.modal-container {
  margin: 0 auto;
  position: relative;
  height: auto;
  color: black !important;
  background-color: #fff;
  border-radius: 0;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  padding: 25px 0;
}

.modal-body {
  margin: 0;
  padding: 0;
}

.signup-strip {
  background-color: #f1f2f2;
  padding: 20px 0;
}

.signup-strip .btn-yellow {
  font-size: 11px;
  font-weight: 600;
  width: 210px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  margin: 0 auto;
  display: block;
}

.social-login {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  margin-bottom: 16px;
}

.social-login i {
  font-size: 16px;
}

.social-login-fb,
.social-login-gplus {
  width: 210px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  color: #fff;
  font-weight: 400;
  font-size: 11px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.social-login-fb:hover,
.social-login-fb:focus,
.social-login-gplus:hover,
.social-login-gplus:focus {
  color: #fff;
  font-weight: 600;
}

.social-login-fb {
  background-color: #3b5998;
}

.social-login-gplus {
  background-color: #de4b39;
}

.social-login-fb i,
.social-login-gplus i {
  margin-right: 7px;
}

.social-login-instagram {
  background: radial-gradient(
    circle at 0% 0%,
    #fdf497 0%,
    #fdf497 0%,
    #fd5949 30%,
    #d6249f 60%,
    #285aeb 100%
  );
}

.login-form {
  width: 90%;
  margin: auto;
}

.login-form input {
  display: block;
  width: 100%;
  margin: 0;
  border: none;
  border-bottom: 1px solid #939598;
  height: 30px;
  margin-bottom: 20px;
  color: #939598;
  font-size: 14px;
}

.login-form ::-webkit-input-placeholder {
  /* Chrome */
  color: #939598;
}

.login-form :-ms-input-placeholder {
  /* IE 10+ */
  color: #939598;
}

.login-form ::-moz-placeholder {
  /* Firefox 19+ */
  color: #939598;
  opacity: 1;
}

.login-form :-moz-placeholder {
  /* Firefox 4 - 18 */
  color: #939598;
  opacity: 1;
}

.login-form input:focus {
  outline-offset: 0;
  outline: none;
  border-bottom-color: #ffcb08;
}

.login-form .btn-white,
.reg-form .btn-white {
  border-color: #939598;
  color: #939598;
  width: 182px;
  line-height: 28px;
  display: block;
  margin: 0 auto;
  cursor: pointer;
}

button.btn-white:hover {
  border: 1px solid #ffcb08;
  background-color: transparent;
  color: #ffcb08;
}

.fpsw {
  display: block;
  text-align: center;
  font-size: 14px;
  color: #000;
  font-weight: 400;
  margin: 20px 0;
}

.log-reg-logo {
  margin: auto;
  display: block;
  max-width: 229px;
  height: auto;
}

.login-modal .modal-body {
  padding-top: 50px;
}

@media only screen and (min-width: 769px) {
  .social-login {
    display: flex;
    flex-flow: row;
  }

  .fpsw {
    float: left;
  }

  .login-form .btn-white {
    float: right;
  }

  .social-login-fb {
    margin: 0 15px 0 0;
  }

  .social-login {
    text-align: center;
  }

  .login-modal .modal-body {
    padding: 50px 70px;
  }

  .fpsw {
    margin: 0;
    line-height: 40px;
  }

  .login-form {
    width: 100%;
    margin: 0;
  }
}

/*========================================================================
                            Registracija
==========================================================================*/
.reg-form {
  width: 90%;
  margin: auto;
}

.reg-form div label {
  display: block;
  font-size: 14px;
  color: #000;
  font-weight: 400;
}

.reg-form input {
  width: 100%;
  height: 40px;
  padding-left: 10px;
  border: 1px solid #d1d3d4;
}

input[type='checkbox'] {
  display: inline-block;
  width: auto;
  height: auto;
}

.reg-form input:focus {
  outline-offset: 0;
  outline: none;
  border: 1px solid #ffcb08;
}

.mb-10 {
  margin-bottom: 10px;
}

.reg-form p {
  color: #939598;
  font-size: 14px;
  font-weight: 400;
}

.reg-checkbox span,
.reg-checkbox label {
  color: #939598 !important;
  font-size: 14px !important;
  font-weight: 400;
  margin-left: 10px;
}

.reg-form .btn-white {
  margin-top: 30px;
}

@media only screen and (min-width: 769px) {
  .reg-form {
    width: 100%;
    margin: auto;
  }
}

/*========================================================================
                            Konkurs fotografija
==========================================================================*/

.contest-photo-modal {
  display: flex;
  flex-wrap: wrap;
}

.contest-photo-modal .container {
  max-width: 98%;
}

.contest-photo-modal .modal-wrapper {
  max-width: none;
}

.contest-photo-modal .modal-header-ss {
  position: relative;
}

.contest-photo-modal .modal-container {
  position: relative;
  padding: 0;
  /* max-height: 510px; */
}

.contest-photo-modal .modal-body {
  background-color: #fff;
}

/* .contest-photo-modal .img-fluid {
  max-height: 510px;
} */

.contest-photo-modal .cpm-bg {
  text-align: center;
  position: relative;
  background-color: #000;

  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 !important;
}

.contest-photo-modal .cpm-bg .img-fluid {
  max-height: 100%;
}

.contest-photo-modal .cpm-bg-fff {
  background-color: #fff;
}

.contest-photo-modal .top-right {
  font-size: 18px;
  color: #000000;
  z-index: 9;
  top: 10px;
  right: 10px;
  width: auto;
  height: auto;
  line-height: 1;
}

.www-icon img:first-of-type {
  display: block;
}

.www-icon img:last-of-type {
  display: none;
}

.www-icon:hover img:last-of-type {
  display: block;
}

.www-icon:hover img:first-of-type {
  display: none;
}

.contest-photo-modal-header {
  border-bottom: 1px solid #f1f2f2;
  margin-right: -15px;
  margin-left: -15px;
  padding: 20px 15px;
  margin-bottom: 20px;
}

.contest-photo-modal-header ul {
  display: flex;
}

.contest-photo-modal-header ul li {
  display: inline-block;
}

.contest-photo-modal-header ul li a i {
  font-size: 23px;
  line-height: 0.9;
  margin-left: 5px;
}

.cpm-social-share {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  display: none;
}

.at-resp-share-element .at-share-btn {
  margin: 0 !important;
}

.at4-show {
  text-align: right;
}

.cpm-bg:hover .cpm-social-share {
  display: flex;
}

.cpm-bg .cpm-social-share {
  display: none;
  align-items: right;
  justify-content: flex-end;
  background-color: rgba(0, 0, 0, 0.7);
}

.cpm-bg .cpm-social-share > span {
  color: #fff;
  line-height: 42px;
  margin-right: 20px;
  font-size: 14px;
  font-weight: 700;
}

.comments {
  max-height: 145px;
  overflow-y: auto;
  margin-bottom: 25px;
}

.comments > div {
  margin-bottom: 10px;
  position: relative;
}

.comments .delete-comment {
  position: absolute;
  right: 10px;
  bottom: 0;
}

.contest-photo-modal h2 {
  font-size: 16px;
  font-weight: 700;
  margin-top: 0;
}

.contest-photo-modal h6 {
  font-size: 14px;
  font-weight: 600;
  display: inline-block;
  margin: 0 6px 0 0;
}

.contest-photo-modal .comments p {
  font-size: 13px;
  font-weight: 400;
  display: inline;
  margin: 0;
}

.contest-photo-modal .cpli-number,
.contest-photo-modal .cpli-vote-strip {
  position: static;
}

.contest-photo-modal .cpli-vote-strip {
  margin-top: 5px;
}

.input-comment {
  margin-bottom: 15px;
}

.input-comment .input-container {
  display: flex;
  border: 1px solid #a7a9ac;
}

.input-comment .input-container:focus-within {
  border-color: #fdcb07;
}

.input-comment input {
  width: 100%;
  height: 40px;
  border: none;
  font-size: 13px;
  padding-left: 8px;
}

.input-comment button {
  border: none;
  background-color: inherit;
  padding: 0 1em;
  transition: all 0.15s;
}

.input-comment button:hover {
  background-color: #f0f0f0;
}

.input-comment ::-webkit-input-placeholder {
  /* Chrome */
  color: #a7a9ac;
}

.input-comment :-ms-input-placeholder {
  /* IE 10+ */
  color: #a7a9ac;
}

.input-comment ::-moz-placeholder {
  /* Firefox 19+ */
  color: #a7a9ac;
  opacity: 1;
}

.input-comment :-moz-placeholder {
  /* Firefox 4 - 18 */
  color: #a7a9ac;
  opacity: 1;
}

.input-comment input:focus {
  outline-offset: 0;
  outline: none;
  border-color: #fdcb07;
}

.cpli-vote-strip div p {
  display: inline;
  color: #939598;
  font-size: 12px;
  font-weight: 400;
}

.contest-photo-modal .cpli-vote-strip div span {
  color: #000;
  font-weight: 400;
  text-shadow: none;
  font-size: 14px;
}

.author-avatar {
  width: 60px;
  height: 60px;
  border-radius: 100%;
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  background-size: cover;
  background-position: center center;
  display: inline-block;
  margin-right: 20px;
}

.author-social {
  display: inline-block;
}

.hdr-wrapper {
  display: flex;
  align-items: center;
}

.contest-photo-modal .cpli-vote-strip {
  justify-content: left;
}

.contest-photo-modal .cpli-vote-strip i {
  font-size: 18px;
}

.contest-photo-modal .vote-div {
  margin-right: 10px;
}

.contest-photo-modal .cpli-vote-strip div span {
  font-size: 15px;
}

.left-angle {
  position: absolute;
  left: 5px;
  top: 50%;
  transform: translateY(-50%);
  color: white;
  font-size: 40px;
  opacity: 0.6;
}

.right-angle {
  position: absolute;
  right: 5px;
  color: white;
  font-size: 40px;
  top: 50%;
  transform: translateY(-50%);
  opacity: 0.6;
}

.right-angle i {
  line-height: 100%;
}

.left-angle:hover {
  opacity: 1;
}

.right-angle:hover {
  opacity: 1;
}

.options-dots {
  position: absolute;
  right: 10px;
  bottom: 10px;
  cursor: pointer;
  font-size: 18px;
  font-weight: 700;
  text-align: right;
  user-select: none;
}

.modal-container .contest-photo-modal .cpm-bg {
  height: 90vh;
}

@media screen and (max-width: 768px) {
  .cpli-vote-strip > div:first-of-type {
    display: block;
    margin-bottom: 10px;
  }

  .cpli-vote-strip > div {
    display: inline-block;
  }

  .contest-photo-modal .cpm-bg {
    padding: 0;
    display: initial;
  }

  .modal-container .contest-photo-modal .cpm-bg {
    height: auto;
  }

  .contest-photo-modal.modal-mask {
    display: block;
  }

  .contest-photo-modal .top-right {
    background: transparent;
    color: #fff;
    text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.8);
  }

  .cpm-bg .cpm-social-share {
    display: flex;
    position: relative;
  }

  .contest-photo-modal {
    overflow-y: auto;
  }

  .comments {
    max-height: 500px;
    overflow-y: auto;
  }

  .modal-mask {
    display: block;
  }

  .rl-like-count,
  .rl-like-count-title,
  .avatar-title,
  .rl-avatar-td {
    display: none;
  }
}

.comments {
  overflow-y: scroll;
  max-height: 400px;
}

.comments .comment-avatar-container {
  margin-right: 10px;
  max-width: 30px;
  max-height: 30px;
}

.comments .comment-avatar-img {
  display: 'inline-block';
  width: 100%;
  height: 100%;
  border-radius: 100%;
}

.comments p {
  position: relative;
}

.comments p:nth-child(odd) {
  background-color: #eee;
}

/*** Rang Lista ***/

.konkurs-img {
  width: 100px;
  height: 100px;
  -webkit-transition: all 0.6s ease-out;
  -moz-transition: all 0.6s ease-out;
  -o-transition: all 0.6s ease-out;
  transition: all 0.6s ease-out;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  display: block;
  position: relative;
  cursor: pointer;
  margin: 0 auto;
}

.rank-list .author-avatar {
  width: 70px;
  height: 70px;
  border: 1px solid #a1a1a16e;
}

.rank-list .table td {
  vertical-align: middle;
  font-size: 14px;
  font-weight: 600;
}

.rank-list-name a {
  font-weight: 400;
  color: #000;
  font-size: 12px;
}

.rank-list .hero-container {
  background-image: url('/img/top-section-bg.jpg');
}

.hc-bg {
  background-size: cover;
  background-repeat: no-repeat;
  text-align: center;
  color: #fff;
  border-bottom: 3px solid #ffcb08;
  padding-top: 60px;
  margin-bottom: 20px;
}

.hero-headline {
  padding: 36px 0;
  text-transform: uppercase;
  font-weight: 400;
  font-size: 48px;
}

.rank-list th {
  border-top: none;
}

.rank-list th {
  border-top: none;
}

.main-header .container-fluid {
  max-width: 1140px;
}

@media screen and (max-width: 768px) {
  .hero-container.hc-bg .hero-overlay-bg {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .hero-headline {
    padding: 0;
    margin-bottom: 0;
    text-transform: uppercase;
    font-weight: 400;
    font-size: 30px;
  }

  .hc-bg {
    padding: 0;
  }

  .main-header .container-fluid {
    display: block;
  }
}

.last-col {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.last-col a .author-avatar {
  margin-right: 10px;
  display: inline-block;
}

.last-col .author-avatar {
  width: 30px;
  height: 30px;
  margin: 0;
}

.last-col .log-reg {
  font-size: 11px;
  text-align: left;
  display: inline-block;
  height: auto;
}

.rank-list-hdr {
  color: #fdcb07;
  font-size: 12px;
  line-height: 0;
  font-weight: bold;
  margin-left: 5px;
}

/*** Static Page ***/

.static-page article {
  margin: 10px 0 30px 0;
}

.static-page.o-konkursu .hc-bg {
  background-image: url('/img/top-section-bg1.jpg');
}

.static-page.pravila-konkursa .hc-bg {
  background-image: url('/img/top-section-bg3.jpg');
}

.rank-list .hc-bg {
  background-image: url('/img/top-section-bg2.jpg');
}

.at-share-btn-elements {
  text-align: center;
  font-weight: 600;
}

.share-modal .modal-body {
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
}

.share-modal h5 {
  margin: 20px 0;
  font-size: 14px;
}

.static-page {
  min-height: 600px;
  padding-bottom: 50px;
}

.static-page.pravila-konkursa article ul li {
  font-size: 13px;
}

.pravila-konkursa article ul li {
  margin-bottom: 10px;
}

/* moj profil */

.moj-profil h3 {
  font-size: 18px;
  font-weight: 400;
  text-transform: uppercase;
  margin: 0 0 25px 0;
}

.contest-my-profile {
  background-color: #f1f2f2;
  padding: 25px 0;
}

.contest-photo-my-profile {
  height: 180px;
  position: relative;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  color: #ffcb08;
}

.contest-photo-my-profile label {
  color: #ffcb08;
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
}

.preview-photo-link {
  margin-top: 25px;
  margin-bottom: 0;
  font-size: 18px;
}

.preview-photo-link a {
  font-weight: 400;
}

.preview-photo-link a:hover {
  color: #ffcb08;
}

.form-group {
  margin: 0;
}

button.btn-yellow {
  margin: 0;
  border: none;
}

.contest-my-profile button.btn-yellow {
  margin-top: 8px;
}

.contest-my-profile .form-control {
  width: 100%;
  color: #a7a9ac;
  padding: 10px 0 0 10px;
  background-color: #fff;
  background-image: none;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0;
  font-size: 12px;
  margin-top: 0;
  margin-bottom: 0;
  height: 132px;
}

.form-control:focus {
  box-shadow: none;
  border: 1px solid #ffcb08;
}

.content-my-profile {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.contest-my-profile .delete-photo {
  position: absolute;
  right: 10px;
  top: 10px;
  line-height: 0;
  color: #ffcb08;
  font-size: 15px;
}

.alert {
  border-radius: 0;
  margin: 0;
}

.alert h4 {
  margin: 0;
  text-align: center;
  font-size: 11px;
  font-weight: 400;
}

.btn-mobile-contest {
  display: none;
}

@media only screen and (max-width: 768px) {
  .contest-photo-my-profile .cpli-overlay,
  .contest-photo-my-profile .content-my-profile,
  .contest-photo-my-profile .delete-photo {
    display: none;
  }

  .btn-mobile-contest {
    width: 100%;
    display: block;
    font-size: 0;
  }

  .btn-mobile-contest a {
    display: inline-block;
    width: 50%;
    text-align: center;
    margin: 0;
  }

  .btn-mobile-contest a:first-of-type {
    border-right: 0.5px solid #f1f2f2;
  }

  .btn-mobile-contest a:last-of-type {
    border-left: 0.5px solid #f1f2f2;
  }

  .preview-photo-link {
    font-size: 14px;
  }
}

.profile-data {
  padding: 50px 0;
}

.profile-data input.form-control {
  max-width: 265px;
  border-radius: 0;
  border: 1px solid #a7a9ac;
  font-size: 12px;
  height: 40px;
}

.profile-data input.form-control:focus {
  border: 1px solid #ffcb08;
}

.profile-data label {
  font-size: 12px;
  color: #000;
  margin-bottom: 5px;
  margin-top: 10px;
}

.profile-data .alert {
  max-width: 256px;
}

.profile-data .author-avatar {
  width: 75px;
  height: 75px;
}

.avatar-flex {
  display: flex;
  align-items: center;
}

.avatar-flex label {
  color: #ffcb08;
  margin: 0;
  font-weight: 400;
  cursor: pointer;
}

.profile-data .btn-white {
  border-color: #939598;
  color: #939598;
  width: 182px;
  line-height: 28px;
  display: block;
  cursor: pointer;
  border-radius: 0;
  margin-top: 20px;
}

.main-header .dropdown-menu {
  position: absolute;
  top: 100%;
  margin: 0;
  right: 0;
  z-index: 7;
  left: 0;
  font-size: 11px;
  color: #bcbec0;
  text-align: left;
  list-style: none;
  background-color: rgba(0, 0, 0, 0.85);
  background-clip: padding-box;
  display: block;
  border-radius: 0;
  float: none;
  min-width: 0;
  padding: 0;
  border: none;
  font-weight: 400;
}

.main-header .dropdown-menu li {
  text-align: center;
}

.main-header .dropdown-menu li:first-of-type {
  padding: 0 0 3px 0;
}

.main-header .dropdown-menu li:last-of-type {
  padding: 0 0 5px 0;
}

#photomachine {
  width: 100%;
  height: 40px;
  padding-left: 10px;
  font-size: 12px;
}

#photomachine:focus {
  border: 1px solid #ffcb08;
}

.photomachinelabel label {
  margin: 0;
}

.photomachinelabel {
  display: flex;
  align-items: center;
}

.photomachinelabel-mb {
  display: none;
}

@media only screen and (max-width: 992px) {
  .photomachinelabel {
    display: none;
  }

  .photomachinelabel-mb {
    display: block;
    margin-top: 5px;
  }
}

.contest-read-more .btn-yellow {
  width: auto;
  padding: 0 10px;
  margin-top: 30px;
  margin-bottom: 30px;
}

/** EDIT MIN WIDTH 992px - 120% **/

@media only screen and (min-width: 1200px) {
  p {
    font-size: 15px;
  }

  .awards-section article > p {
    font-size: 15px;
  }

  h4,
  .h4 {
    font-size: 21px;
  }

  .awards-section article > ul li,
  .awards-section article > a {
    font-size: 15px;
  }

  .contest-counter h5 {
    font-size: 17px;
  }

  .contest-counter .contest-counter-clock > div .cc-number {
    width: 42px;
    height: 51px;
    font-size: 32px;
  }

  .contest-counter .contest-counter-clock {
    width: 238px;
  }

  .contest-counter .contest-counter-clock > div span {
    font-size: 12px;
  }

  .sort-by li a {
    font-size: 12px;
  }

  .arrow-yellow {
    top: 3px;
  }

  .btn-yellow,
  .btn-white {
    width: 218px;
    height: 48px;
    line-height: 48px;
    font-size: 16px;
  }

  #home .hero-container p {
    font-size: 15px;
  }

  .main-nav ul li a {
    font-size: 14px;
  }

  .login-required p {
    font-size: 12px;
  }

  footer .footer-nav ul li a {
    font-size: 14px;
  }

  .logo {
    line-height: 72px;
  }

  .logo img {
    width: 119px;
    height: auto;
  }

  .main-nav ul {
    line-height: 72px;
  }

  small {
    font-size: 12px;
  }

  .log-reg li a {
    font-size: 12px;
  }

  .rank-list-hdr {
    font-size: 14px;
  }

  .footer-logo {
    width: 132px;
    height: 60px;
  }

  .main-header .dropdown-menu {
    font-size: 12px;
  }

  .rank-list-name a {
    font-size: 14px;
  }

  .rank-list .table td {
    font-size: 16px;
  }

  .contest-read-more .btn-yellow {
    padding: 0 17px;
  }
  .about-contest-photo {
    font-size: 13px;
    margin-bottom: 0px;
  }
  .about-contest-read-more p {
    font-size: 13px;
  }

  #contest-logo {
    width: 191px;
    height: 197px;
  }
  .static-page.pravila-konkursa article ul li {
    font-size: 14px;
  }

  .uslovi-koriscenja article ol li {
    font-size: 14px;
  }
}

ol {
  margin: 0;
  padding: 0;
  list-style: none;
}

.photomachine-by {
  color: #939598;
  font-size: 12px;
  font-weight: 400;
  margin-top: 5px;
  margin-bottom: 8px;
}

.photomachine-by span {
  color: #000;
}

.contest-photo-modal-header {
  margin-bottom: 12px;
  padding-top: 15px;
  padding-bottom: 15px;
}

.rm-c-modal {
  overflow-y: scroll;
  display: block;
}

.rm-c-modal .modal-wrapper {
  margin: 0 auto;
  max-width: 768px;
}

.rm-c-modal .modal-body {
  padding: 0 30px;
  font-size: 14px;
}

.about-contest-read-more > ul > li {
  margin-bottom: 10px;
  margin-top: 10px;
}

.about-contest-read-more > ul > li > br {
  line-height: 2;
}

.btn-silver {
  border-color: #939598;
  color: #939598;
  cursor: pointer;
  width: auto;
  padding: 0 10px;
}

.error-404 {
  min-height: calc(100vh - 202px);
  padding: 72px;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.error-404 h1 {
  text-transform: uppercase;
  font-weight: 700;
  color: #bcbec0;
}

.error-404 footer .footer-social li a {
  color: #bcbec0;
}

hr {
  margin-bottom: 46px;
}

@media only screen and (max-width: 768px) {
  p,
  .awards-section article > p,
  .awards-section article > ul li,
  .awards-section article > a,
  footer .footer-nav ul li a,
  .main-nav ul li a {
    font-size: 14px;
  }

  h4,
  .h4 {
    font-size: 19px;
  }

  .main-header .dropdown-menu {
    font-size: 13px;
  }
  .last-col .log-reg {
    font-size: 12px;
  }
}

@media only screen and (max-width: 768px) {
  p,
  .awards-section article > p,
  .awards-section article > ul li,
  .awards-section article > a,
  footer .footer-nav ul li a,
  .main-nav ul li a {
    font-size: 14px;
  }

  h4,
  .h4 {
    font-size: 19px;
  }

  .main-header .dropdown-menu {
    font-size: 13px;
  }

  .last-col .log-reg {
    font-size: 12px;
  }

  .insta-fb-follow {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

/* social strip */
.social-follow-strip {
  z-index: 999;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 35px;
  background-color: rgba(0, 0, 0, 0.8);
  color: #fff;
  display: flex;
  flex-flow: row;
  align-items: center;
}

.social-follow-strip p {
  font-size: 10px;
  margin: 0;
  text-transform: uppercase;
  font-weight: 600;
  z-index: 9999;
  user-select: none;
  cursor: default;
  display: inline-block;
  margin-right: 4px;
}

.social-follow-strip i {
  position: absolute;
  right: 15px;
  top: 5px;
  color: #fff;
  bottom: 0;
}

.social-follow-strip div {
  display: inline-block;
}

.social-follow-strip > div > div {
  display: flex;
  justify-content: center;
  align-items: center;
}

.insta-fb-follow {
  display: flex !important;
  align-items: center;
  justify-content: center;
}

@media only screen and (max-width: 768px) {
  .social-follow-strip > div > div {
    flex-flow: column;
  }

  .social-follow-strip i {
    position: absolute;
    right: 5px;
    top: 5px;
    color: #fff;
    bottom: 0;
  }
}

.footer-strip-frist {
  background-color: #000;
  padding: 20px 0 0 0;
}

.footer-strip-frist p {
  margin: 0;
  color: white;
  font-weight: 600;
}

.footer-strip-frist img {
  max-height: 50px;
}

.footer-strip-frist p img {
  margin-left: 35px;
}

.footer-strip-frist .nova-fabrika {
  max-height: 50px;
}

.footer-strip-frist .radijus-vektor {
  min-height: 40px;
  margin-left: 20px;
}

.footer-strip-frist .mineweb-logo {
  min-height: 35px;
}

@media only screen and (max-width: 768px) {
  .footer-strip-frist p {
    text-align: center;
  }

  .footer-strip-frist .radijus-vektor {
    margin-top: 10px;
  }

  .footer-strip-frist .radijus-vektor,
  .footer-strip-frist .mineweb-logo {
    display: block;
    margin: auto;
  }

  .footer-strip-frist .mineweb-logo {
    margin: 10px auto;
  }

  .footer-strip-frist p img {
    margin-left: 0;
  }

  .footer-strip-frist .aq-logo {
    margin-left: 10px;
  }
}

.share-button {
  transition: all 0.2s;
}

.share-button:hover {
  transform: translate(0, -5px);
}

.w-full {
  width: 100%;
}

.main-container {
  min-height: calc(100vh - 258px);
}

.pointer {
  cursor: pointer;
}
.select-none {
  user-select: none;
}

/* Edits N - SS v2 */

input#photo-device {
  color: #a7a9ac;
  padding: 10px;
  background-color: #fff;
  background-image: none;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0;
  font-size: 12px;
  margin-top: 0;
  margin-bottom: 10px;
  line-height: 20px;
}

.about-social {
  display: flex;
  flex-flow: row;
  justify-content: center;
  font-size: 40px;
}

.about-social li {
  margin: 5px;
}

.about-social li a {
  color: black;
}

.about-info {
  margin-bottom: 20px;
}

.sponsorship {
  background: #ffcb09;
  padding: 10px 20px;
  color: #000;
  font-weight: 600;
  font-size: 14px;
  max-width: 500px;
}

.sponsorship:hover {
  color: #fff;
}

.sponsorship:active {
  color: #fff;
}

@media only screen and (max-width: 768px) {
  .modal-body {
    position: relative;
    overflow-y: auto;
    height: 90vh;
  }
}

.alert-modal .modal-mask {
  display: flex;
  justify-content: center;
  align-items: center;
}
.alert-modal .modal-body {
  height: auto;
}
.alert-modal .modal-wrapper {
  width: 100vw !important;
}

@media only screen and (max-width: 768px) {
  .alert-modal .modal-wrapper {
    width: 700px;
  }
}
